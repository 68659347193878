
import React from 'react';
import styled from "styled-components"; 

const Sidebar = styled.div`
    width:100%; 
    &.floating{ 
        width:340px; 
        float:right; 
        margin-left:20px; 
        margin-bottom:20px; 
    }

    @media (max-width:991.98px) {  
        margin-top:25px; 

        &.floating{ 
            float:none; 
            margin:25px auto; 
        }
    }
`;
/*
	&.green{ 
		background-color:#169300; 
	} 
	&.green.inverse{ 
        border:1px solid #169300; 
        background-color:#FFFFFF; 
	} 
	&.blue{ 
		background-color:#008BE0; 
	} 
	&.blue.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #008BE0; 
        color:#008BE0; 
	} 
	&.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #008BE0; 
        color:#008BE0; 
	} 
	&.yellow{ 
        background-color:#fcae00; 
        color:#000000; 
	} 
	&.yellow.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #fcae00; 
        color:#fcae00; 
	} 
	&.red{ 
        background-color:#a50000; 
	} 
	&.red.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #a50000; 
        color:#a50000;
	} 
	&.orange{ 
        background-color:#C96E00; 
	} 
	&.orange.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #C96E00; 
        color:#C96E00; 
	} 
	&.purple{ 
        background-color:#7500C9; 
	} 
	&.purple.inverse{ 
        background-color:#FFFFFF;
        border:1px solid #7500C9; 
        color:#7500C9; 
    } 
    */
const SidebarTitle = styled.div`
    display:block; 
    position:relative; 
    height:43px; 
    width:100%; 
    overflow:hidden; 

    & h3{ 
        display:inline-block; 
        position:relative; 
        z-index:20; 
        color:#FFFFFF; 
        margin:7px 0px 0px 10px; 
        font-size:1.4rem; 
    }
    & svg{ 
        position:absolute; 
        top:0px; 
        right:0px; 
        z-index:10; 
    }
    &.blue svg .drop-shadow {
        fill: #004c7a;
    }
    &.blue svg .main-bg, 
    &.blue svg .drop-shadow {
        fill-rule: evenodd;
    }
    &.blue svg .main-bg {
        fill: #008BE0;
    }
    &.yellow h3{ 
        color:#212529;
    }
    &.yellow svg .drop-shadow {
        fill: #C96E00;
    }
    &.yellow svg .main-bg, 
    &.yellow svg .drop-shadow {
        fill-rule: evenodd;
    }
    &.yellow svg .main-bg {
        fill: #fcae00;
    }

    &.green h3{ 
        color:#FFFFFF;
    }
    &.green svg .drop-shadow {
        fill: #005600;
    }
    &.green svg .main-bg, 
    &.green svg .drop-shadow {
        fill-rule: evenodd;
    }
    &.green svg .main-bg {
        fill: #169300;
    }
    

`;

const SidebarContent = styled.div`
    padding:10px; 
    margin-right:12px; 

    & h4{ 
        font-size:1.2rem; 
    }
    & p{ 
        font-size:.9em; 
    }
    &.blue{ 
        background:rgba(8,128,215,.25); 
    }
    &.yellow{ 
        background:rgba(252,174,0,.25); 
    }
    &.green{ 
        background:rgba(22,147,0,.25); 
    }
    & a{ 
        text-decoration:none; 
    }
    & p.clickToExpand{ 
        padding-left:0px; 
        padding-right:0px; 
        color:#000; 
    }
    & p.clickToExpand .btn{ 
        position:relative; 
        z-index:10; 
        margin:0px; 
        padding:0px; 
        color:#000; 
    }
    & p.clickToExpand img{ 
        position:relative; 
        z-index:10; 
    }
    & p.clickToExpand svg{ 
        position:absolute; 
        z-index:20; 
        top:5px; 
        right:5px; 
    }
    & img{ 
        border-bottom-right-radius:20px; 
    }
`;

export default function ArticleSidebar({ id,headline,color,children,floating }) {
 
  if(id===undefined) id="ArticleSidebar"; 

  if(color===undefined) color="blue"; 

  return (
    <Sidebar id={id} className={((floating===true)?'floating':'')+' '+color}>
        <SidebarTitle className={color}>
            <h3>{headline}</h3>
            <svg data-name="Subheading BG" xmlns="http://www.w3.org/2000/svg" width="587" height="43" viewBox="0 0 587 43"><polygon class="drop-shadow" points="574 43 0 43 0 4 587 4 574 43"/><polygon class="main-bg" points="570 39 0 39 0 0 583 0 570 39"/></svg>
        </SidebarTitle>
        <SidebarContent className={color}>
            {children}
        </SidebarContent>
    </Sidebar>
  )
}








