import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
// import { OutboundLink } from "gatsby-plugin-google-gtag"

import Tier3Layout from "../../components/content/Tier3Layout"
import ArticleSidebar from "../../components/content/ArticleSidebar"
import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
import SampleItineraries from "../../components/content/SampleItineraries"
import PageSectionHeadline from "../../components/content/PageSectionHeadline"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'

import '../../styles/tier3.css'
import '../../styles/cruises.css'
import cruiselogo_carnival from '../../images/supplier_logos/cruiselogo_carnival.png'; 
import cruiselogo_rcl from '../../images/supplier_logos/cruiselogo_rcl.png'; 
import cruiselogo_vv from '../../images/supplier_logos/cruiselogo_vv.png'; 
import cruiselogo_viking from '../../images/supplier_logos/cruiselogo_viking.png'; 
import cruiselogo_atlas from '../../images/supplier_logos/cruiselogo_atlas.png'; 
import cruiselogo_celebrity from '../../images/supplier_logos/cruiselogo_celebrity.png'; 
import cruiselogo_crystal from '../../images/supplier_logos/cruiselogo_crystal.png'; 
import cruiselogo_cunard from '../../images/supplier_logos/cruiselogo_cunard.png'; 
import cruiselogo_disney from '../../images/supplier_logos/cruiselogo_disney.png'; 
import cruiselogo_holland from '../../images/supplier_logos/cruiselogo_holland.png'; 
import cruiselogo_msc from '../../images/supplier_logos/cruiselogo_msc.png'; 
import cruiselogo_norwegian from '../../images/supplier_logos/cruiselogo_norwegian.png'; 
import cruiselogo_oceania from '../../images/supplier_logos/cruiselogo_oceania.png'; 
import cruiselogo_olivia from '../../images/supplier_logos/cruiselogo_olivia.png'; 
import cruiselogo_paulgauguin from '../../images/supplier_logos/cruiselogo_paulgauguin.png'; 
import cruiselogo_princess from '../../images/supplier_logos/cruiselogo_princess.png'; 
import cruiselogo_ritz from '../../images/supplier_logos/cruiselogo_ritz.png'; 
import cruiselogo_scenic from '../../images/supplier_logos/cruiselogo_scenic.png'; 
import cruiselogo_seabourn from '../../images/supplier_logos/cruiselogo_seabourn.png'; 
import cruiselogo_silversea from '../../images/supplier_logos/cruiselogo_silversea.png'; 
import cruiselogo_uncruise from '../../images/supplier_logos/cruiselogo_uncruise.png'; 
import carnival_ship_1 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-ship-1.jpg'; 
import carnival_ship_2 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-ship-2.jpg'; 
import carnival_ship_3 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-ship-3.jpg'; 
import carnival_ship_4 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-ship-4.jpg'; 
import carnival_ship_5 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-ship-5.jpg'; 
import carnival_activities_1 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-activities-1.jpg'; 
import carnival_activities_2 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-activities-2.jpg'; 
import carnival_activities_3 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-activities-3.jpg'; 
import carnival_activities_4 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-activities-4.jpg'; 
import carnival_activities_5 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-activities-5.jpg'; 
import carnival_activities_6 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-activities-6.jpg'; 
import carnival_kids_1 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-kids-1.jpg'; 
import carnival_kids_2 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-kids-2.jpg'; 
import carnival_kids_3 from '../../images/sampleitinerary_carousels/cruises/cruiselines-carnival-kids-3.jpg'; 
import rcl_ship_1 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-ship-1.jpg'; 
import rcl_ship_2 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-ship-2.jpg'; 
import rcl_ship_3 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-ship-3.jpg'; 
import rcl_ship_4 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-ship-4.jpg'; 
import rcl_ship_5 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-ship-5.jpg'; 
import rcl_ship_6 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-ship-6.jpg'; 
import rcl_activities_1 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-activities-1.jpg'; 
import rcl_activities_2 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-activities-2.jpg'; 
import rcl_activities_3 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-activities-3.jpg'; 
import rcl_activities_4 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-activities-4.jpg'; 
import rcl_activities_5 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-activities-5.jpg'; 
import rcl_activities_6 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-activities-6.jpg'; 
import rcl_activities_7 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-activities-7.jpg'; 
import rcl_activities_8 from '../../images/sampleitinerary_carousels/cruises/cruiselines-royal-activities-8.jpg'; 
import vv_ship_1 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-ship-1.jpg'; 
import vv_ship_2 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-ship-2.jpg'; 
import vv_ship_3 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-ship-3.jpg'; 
import vv_ship_4 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-ship-4.jpg'; 
import vv_ship_5 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-ship-5.jpg'; 
import vv_ship_6 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-ship-6.jpg'; 
import vv_activities_1 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-activities-1.jpg'; 
import vv_activities_2 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-activities-2.jpg'; 
import vv_activities_3 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-activities-3.jpg'; 
import vv_activities_4 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-activities-4.jpg'; 
import vv_activities_5 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-activities-5.jpg'; 
import vv_activities_6 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-activities-6.jpg'; 
import vv_activities_7 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vv-activities-7.jpg'; 
import vrc_ship_1 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-ship-1.jpg'; 
import vrc_ship_2 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-ship-2.jpg'; 
import vrc_ship_3 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-ship-3.jpg'; 
import vrc_ship_4 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-ship-4.jpg'; 
import vrc_ship_5 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-ship-5.jpg'; 
import vrc_ship_6 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-ship-6.jpg'; 
import vrc_ship_7 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-ship-7.jpg'; 
import vrc_ship_8 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-ship-8.jpg'; 
import vrc_ship_10 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-ship-10.jpg'; 
import vrc_places_1 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-places-1.jpg'; 
import vrc_places_2 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-places-2.jpg'; 
import vrc_places_3 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-places-3.jpg'; 
import vrc_places_4 from '../../images/sampleitinerary_carousels/cruises/cruiselines-vrc-places-4.jpg'; 




const Cruises = () => {
    const pageData = {
                        headline:{
                            text:<>Cruise Vacations are Back!</>,
                            iframe:"https://storage.googleapis.com/outward-website-static-resources/page-headings/cruises/CruisesHero.html",
                            hero_height:'450',
                            id:'Cruises'
                        },
                        sectionnav:{section:'vacations'},
                        seo:{
                            title:'Cruise Vacations are Back',
                            description:'There is a cruise for every interest and style - from big parties to small ship adventures. And cruising is one of the safest ways to travel right now, with an incredible bang for your buck!',
                            image:'/images/socialmedia/socialmediaimage_adventure.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('Cruises',location); 
    },[location]);

    const { search } = useLocation()
    const [showConsultationForm,setShowConsultationForm] = useState(false); 

    const [carnivalCarousel1Index, setCarnivalCarousel1Index] = useState(0);
    const [rclCarousel1Index, setRclCarousel1Index] = useState(0);
    const [virginCarousel1Index, setVirginCarousel1Index] = useState(0);
    const [vikingCarousel1Index, setVikingCarousel1Index] = useState(0);

    const handleCarouselSelect = (section,carousel,selectedIndex) => {
        GoogleAnalyticsEvent("sampleitinerary_carousel_interaction",{section:section,carousel:carousel})
        if(section==='carnival'){ 
            if(carousel===1){ 
                setCarnivalCarousel1Index(selectedIndex); 
            } 
        } else if(section==='rcl'){ 
            if(carousel===1){ 
                setRclCarousel1Index(selectedIndex); 
            } 
        } else if(section==='virgin'){ 
            if(carousel===1){ 
                setVirginCarousel1Index(selectedIndex); 
            }
        }else if(section==='viking'){ 
            if(carousel===1){ 
                setVikingCarousel1Index(selectedIndex); 
            }
        }
    };
    
    function startConsultationRequest(which){ 
        GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
        setShowConsultationForm(true); 
    }
    
    function getStartingPane(){ 
        if(search!==""){ 
            var querystring = search.substring(1); 
            var querystringarray = querystring.split('&'); 
            var thisParam; 
            for(var i=0;i<querystringarray.length;i++){ 
                thisParam = querystringarray[i].split('='); 
                if(thisParam[0]==='view'){ 
                    return thisParam[1]; 
                    // return Itineraries[thisParam[1]]; 
                }
            }
        } else { 
            return ''; 
        }
    }

  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier3Layout headline={pageData.headline} sectionnav={pageData.sectionnav} showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

            <article>
            <p>Cruising is coming back! And it’s one of the safest ways to vacation now—with an incredible bang for your buck. Whether you’re looking for an easy way to vacation with family, or a romantic getaway along an idyllic European river, to adults-only party ships and small-ship adventures to unspoiled lands, Outward works with virtually every cruise line to match you with the perfect vacation experience.</p>

                        <p>Going on a cruise lets you bundle a lot of worry-free fun into your vacation. Some people love the theme park at sea that big ships offer. Extended families love to cruise because it’s low-impact for grandparents while high-energy for the kids. Millennials and Gen Z can tour the world by day and party by night for a price that easily fits their budget. Here’s why a cruise makes for a great vacation idea:</p>
                        
                        <ArticleSidebar floating={true} id="CruiseVideoSidebar" headline={<>Cruising Again Safely</>} color="blue">
                            <iframe title="Video: Working to Resume Cruising" src="https://www.facebook.com/plugins/video.php?height=414&href=https%3A%2F%2Fwww.facebook.com%2FCLIAGlobal%2Fvideos%2F257277732460364%2F&show_text=false&width=300&t=0" width="300" height="414" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>
                        </ArticleSidebar>
                        <ul>
                            <li><b>A lot for a little money</b><br />
                                Your room, food, entertainment, and many amenities are all included in one price. And right now, cruise lines are offering great rates and discounts, especially via travel advisors. Sure, some things like the spa, cocktails, or tours on shore might cost a little extra, but the overall value for your dollar is astounding when you run the numbers. A cruise to the Caribbean is even cheaper than alternative travel styles, and you only have to unpack once! </li>
                            <li><b>Cruises are safer and healthier</b><br />
                                Cruise lines are requiring passengers to be vaccinated against COVID-19, and are limiting the capacity of their ships to avoid excessive crowds. During the downtime in 2020, they made a ton of improvements to their air handling systems, and implemented tougher standards and protocols to ensure a clean and healthy ship. </li>
                        
                            <li><b>There is something for everyone</b><br />
                                Large cruise ships are basically theme parks-at-sea: with the newest ships providing some really cool things to do—like a roller coaster or go-cart track, full-sized basketball courts, numerous pools (including adults-only pools), decadent spa and massage treatments, and Broadway-quality entertainment. And if a city-ship isn’t your idea of fun, Outward will help you find a smaller cruise that goes to places you can only reach by boat, with a cozier ship for more refined tastes. </li>
                            <li><b>The food is fantastic</b><br />
                                The cruise lines have dramatically upscaled their dining options over the last ten years, with chefs from all over the world bringing their delicious menus and fine dining expertise. Gone are the days of tuxedo and ball gown requirements—you can wear what you want, eat what you want, and the free food flows like milk and honey everywhere on the ship. Foodies can choose from specialty restaurants, too, and smaller ships often use locally sourced ingredients from along the journey. </li>
                        </ul>
                        <p>Outward Travel can help you find the cruise experience that fits your vacation style and the experience you’re looking for. Let’s have a chat to get started!</p>
                        <ConsultationRequestTrigger color="green" onClick={()=>{startConsultationRequest('main_content')}} />
            </article>

            <SampleItineraries id="Cruises_Agendas" 
                sectionHeadline={<>The Latest Cruise Ships &amp; Features</>} 
                color="blue" 
                displayFirst={getStartingPane()} panes={[
                {
                    headline:"You're In for a Great Time",
                    linktext:"Carnival",
                    key:"carnival",
                    content:<>
                    <Row>
                        <Col xs={{span:12,order:2}} sm={{span:12,order:2}} md={{span:12,order:2}} lg={{span:12,order:2}} xl={{span:10,order:1}}>
                            <p>A Carnival vacation is all about fun at sea and ashore! Come as you are, and you'll feel right at home with 
                                comfy staterooms, delicious food, exciting nightlife, spectactular entertainment, activities for kids, 
                                and places to relax. Every day you're on your way to a different port and a new adventure! 
                            </p>
                        </Col>
                        <Col xs={{span:12,order:1}} sm={{span:12,order:1}} md={{span:12,order:1}} lg={{span:12,order:1}} xl={{span:2,order:2}}>
                            <img src={cruiselogo_carnival} width="200" height="49" className="sample_cruiseline_logo" alt="Carnival Logo" />
                        </Col>
                    </Row>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('carnival',1,selectedIndex)}} activeIndex={carnivalCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_ship_1} alt="Inifinity Tides Pool on board the new Carnival Mardis Gras" />
                            <Carousel.Caption>
                            <p>On board the all-new Carnival Mardis Gras, enjoy the outdoor deck area with an infinity tides pool and two whirlpools.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_ship_2} alt="Chairs on deck at sunset" />
                            <Carousel.Caption>
                            <p>Relax on the Lido deck while the sun sets, before heading off to the casino or one of the many rockin' cocktail bars.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_ship_3} alt="Carnival Mardis Gras stateroomm" />
                            <Carousel.Caption>
                            <p>Staterooms aboard the Mardis Gras have been redesigned for more comfort, lighting, and storage. <span className="hidden-xs hidden-sm hidden-md">Even the interior rooms feel airy and welcoming.</span> You're sure to have a great night's sleep<span className="hidden-xs hidden-sm hidden-md">, or a nap</span>!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_ship_4} alt="One of the pools onboard Carnival Ships" />
                            <Carousel.Caption>
                            <p>With multiple pools all around the ship&mdash;some for adults-only&mdash;you'll never feel overcrowded as you relax in the sun.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_ship_5} alt="Four friends watching the ocean" />
                            <Carousel.Caption>
                            <p>There is something magical about being at sea, especially with your friends!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_activities_1} alt="Carnival onboard spa" />
                            <Carousel.Caption>
                            <p>Anyone can find a reason to sigh "ahh" at the onboard spa.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_activities_2} alt="Jazz dancers on board a Carnival ship" />
                            <Carousel.Caption>
                            <p>Carnival ships are loaded from bow to stern with great entertainment.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_activities_3} alt="Sky-ride on board a Carnival Cruise ship" />
                            <Carousel.Caption>
                            <p>Every ship has some of the most innovative ways to have fun on those cruising days between ports. <span className="hidden-xs hidden-sm hidden-md">Sky-rides, water parks, and even a roller coaster on board the all-new Mardis Gras!</span></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_activities_4} alt="Full-sized basketball court onboard Mardis Gras" />
                            <Carousel.Caption>
                            <p>You can even pick up a game of basketball on the full-sized court, knock back nine holes on the mini-golf course, or go swinging around the ropes<span className="hidden-xs hidden-sm hidden-md"> course</span>!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_activities_5} alt="Evening party on board Carnival Mardis Gras" />
                            <Carousel.Caption>
                            <p>Social butterflies will flourish on board Carnival's ships!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_activities_6} alt="Water slides on board Carnival Mardis Gras" />
                            <Carousel.Caption>
                            <p>The Mardis Gras features three large-scale spiraling slides, a giant dumping bucket, twin racing slides and more<span className="hidden-xs hidden-sm hidden-md"> — you know, the works — are all practically overflowing for your hydro-delight</span>!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_kids_1} alt="Family suite on board a Carnival ship" />
                            <Carousel.Caption>
                            <p>There's room for the whole family onboard Carnival ships!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_kids_2} alt="Boy at the water park" />
                            <Carousel.Caption>
                            <p>While the adults relax, there's always something for the kids to do!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={carnival_kids_3} alt="Kids on the ropes course" />
                            <Carousel.Caption>
                            <p>While the adults relax, there's always something for the kids to do!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>


                    <p className="legalease">Photos &copy; Carnival Cruise Lines. Activities and amenities vary by ship.</p>
                    
                    <ConsultationRequestTrigger color="red"  
                        text={<>Choose Fun. Choose Carnival!
                         <small>Let's talk about your vacation dreams!</small></>} 
                        onClick={()=>{startConsultationRequest('sample_carnival')}} />
                    </>
                },
                {
                    headline:"A Vacation Worth Waiting For",
                    linktext:"Royal Caribbean",
                    key:"royal-caribbean",
                    content:<>
                    <Row>
                        <Col xs={{span:12,order:2}} sm={{span:12,order:2}} md={{span:12,order:2}} lg={{span:12,order:2}} xl={{span:10,order:1}}>
                            <p>Royal Caribbean knows your vacation time is precious, and choosing how you spend it is more important than ever. 
                                Their ships are chock-full of innovations that set the standard for comfort, activities, and relaxation. 
                                The crew is dedicated to the highest quality, award-winning service that Royal Caribbean is known for. Experience
                                the high life on the high seas.  
                            </p>
                        </Col>
                        <Col xs={{span:12,order:1}} sm={{span:12,order:1}} md={{span:12,order:1}} lg={{span:12,order:1}} xl={{span:2,order:2}}>
                            <img src={cruiselogo_rcl} width="200" height="50" className="sample_cruiseline_logo" alt="Royal Caribbean Logo" />
                        </Col>
                    </Row>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('rcl',1,selectedIndex)}} activeIndex={rclCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_ship_1} alt="Harmony of the Seas docked in Labadee, Haiti" />
                            <Carousel.Caption>
                            <p>Royal Caribbean continues to push the limits of what you can imagine about seabourn vacations. <span className="hidden-xs hidden-sm hidden-md">With amazing ships sailing all over the world, like the Harmony of the Seas, shown here docked in Haiti.</span></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_ship_2} alt="Oceanview Cabin onboard the Harmony of the Seas" />
                            <Carousel.Caption>
                            <p>If it wasn't for the gorgeous views through the porthole window, you'd forget you were on board a cruise ship with cabins like these.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_ship_3} alt="Royal Loft Suite onboard the Harmony of the Seas" />
                            <Carousel.Caption>
                            <p>Make yourself at home after a full day of adventure - these rooms have all the creature comforts you'd expect from a vacation<span className="hidden-xs hidden-sm hidden-md"> (and some you probably haven't thought of)</span>. </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_ship_4} alt="Solarium spa on board Oasis of the Seas" />
                            <Carousel.Caption>
                            <p>Enjoy the spa on board the recently amplified Oasis of the Seas.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_ship_5} alt="Oasis of the Seas from above" />
                            <Carousel.Caption>
                            <p>There's lots of ways for everyone in your family to have fun on board.<span className="hidden-xs hidden-sm hidden-md"> The Oasis of the Seas even has multiple water slides, in addition to tons of other amenities and activities.</span> </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_ship_6} alt="The Aqua Theatre show on board Harmony of the Seas" />
                            <Carousel.Caption>
                            <p><span className="hidden-xs hidden-sm hidden-md">Catch a show at the Aqua Theatre on board the Harmony of the Seas. </span>Broadway-quality entertainment is always available onboard these big, beautiful ships, and it's included in your vacation price!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_activities_1} alt="Flowrider on the Oasis of the Seas" />
                            <Carousel.Caption>
                            <p>From lounging by the pool to riding the waves in the FlowRider on board Oasis-class vessels.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_activities_2} alt="Ultimate Abyss Slide" />
                            <Carousel.Caption>
                            <p>At 10 stories high, the Ultimate Abyss slide is the tallest slide on the sea. Are you brave enough?</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_activities_3} alt="Rock wall" />
                            <Carousel.Caption>
                            <p>Climb to new heights on the Royal Caribbean rock wall!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_activities_4} alt="Clash of Crystal City experience" />
                            <Carousel.Caption>
                            <p>In Clash of Crystal City, the Laser Tag experience on baord Oasis of the Seas, the solar system is caught in a cold, freezing future. Battle for control and own the future!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_activities_5} alt="Bumper Cars on the Ovation of the Seas" />
                            <Carousel.Caption>
                            <p>On the Ovation of the Seas, family fun is just a bump away in the Seaplex bumper cars!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_activities_6} alt="Supercell water slide, Symphony of the Seas" />
                            <Carousel.Caption>
                            <p>Ride the Supercell water slide at the Perfect Storm waterpark on board the Symphony of the Seas</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_activities_7} alt="Spa Treatment" />
                            <Carousel.Caption>
                            <p>Before your romantic date time, enjoy a calming spa treatment at the decadent onbaord spa.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={rcl_activities_8} alt="Spa Facial" />
                            <Carousel.Caption>
                            <p>Feel rejunivated and ready for life back home with all sorts of spa and massage treatments.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>


                    <p className="legalease">Photos &copy; Royal Caribbean Inc. Activities and amenities vary by ship.</p>
                    
                    <ConsultationRequestTrigger color="red"  
                        text={<>See What You've Been Missing
                         <small>Let's talk about your vacation dreams!</small></>} 
                        onClick={()=>{startConsultationRequest('sample_royalcaribbean')}} />
                    </>
                },
                {
                    headline:"An Epic Sea Change is Launching",
                    linktext:"Virgin Voyages",
                    key:"virgin",
                    content:<>
                    <Row>
                        <Col xs={{span:12,order:2}} sm={{span:12,order:2}} md={{span:12,order:2}} lg={{span:12,order:2}} xl={{span:10,order:1}}>
                            <p>Virgin Voyages is reinventing cruising for people in their 20s, 30s, and 40s, with yacht-inspired, adults-only
                                ships. On a Virgin Voyage, you're treated like a rock star, with service and luxury that isn't stuffy or formal. 
                                Awesome entertainment, amazing food, creative and cozy cabins, invigorating fitness options, and food, tips, WiFi all included combine to 
                                an all new cruise experience for a new generation of cruisers. With mostly 4 or 5 day voyages, it's a perfect quick getaway to rest and recharge. 
                            </p>
                        </Col>
                        <Col xs={{span:12,order:1}} sm={{span:12,order:1}} md={{span:12,order:1}} lg={{span:12,order:1}} xl={{span:2,order:2}}>
                            <img src={cruiselogo_vv} width="150" height="150" className="sample_cruiseline_logo" alt="Virgin Voyages Logo" />
                        </Col>
                    </Row>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('virgin',1,selectedIndex)}} activeIndex={virginCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_activities_1} alt="Two guys at a pool on board a Virgin Voyage" />
                            <Carousel.Caption>
                            <p>Relax, recharge, and feel like yourself at the one of the pools. <span className="hidden-xs hidden-sm hidden-md">And incidentally, Virgin Voyages is the only major cruise line to allow topless sunbathing in select areas.</span></p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_activities_7} alt="Onboard Casino" />
                            <Carousel.Caption>
                            <p>Meet Lady Luck at the onboard casino</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_activities_2} alt="Kareoke party" />
                            <Carousel.Caption>
                            <p>With a younger clientele, these ships are poppin' with a ton of laughs, entertainment, and fun!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_activities_3} alt="Food plate from Virgin Voyages" />
                            <Carousel.Caption>
                            <p>Virgin Voyages ditch the buffet to bring you made-to-order food and brunch from 20+ unique eateries. </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_activities_4} alt="Group eating dimsum on a Virgin Voyage" />
                            <Carousel.Caption>
                            <p>Pairing fresh ingredients and vibrant flavors with ever-evolving ways to experience food, Virgin Voyages has elevated cruise dining<span className="hidden-xs hidden-sm hidden-md"> and minimizes waste</span>.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_activities_5} alt="Enjoying the sauna" />
                            <Carousel.Caption>
                            <p>From deep-tissue massages and hi-tech facials to a mudroom and hammam with a view — they call it the Redemption Spa for a reason.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_activities_6} alt="Jogging track on a Virgin Voyages ship" />
                            <Carousel.Caption>
                            <p>The Indoor and Outdoor Fitness Club comes complete with a boxing ring, training zone, and unparalleled views of the sun-kissed horizon.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_ship_1} alt="Virgin Voyage Suite" />
                            <Carousel.Caption>
                            <p>Luxury yachting meets rock and roll royalty with a Suite onboard a Virgin Voyage.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_ship_3} alt="Virgin Voyage Cabin" />
                            <Carousel.Caption>
                            <p>The cabins are trendy, comfortable, and customizable—with unique spaces for solo travelers, or transform a cabin to comfortably sleep four<span className="hidden-xs hidden-sm hidden-md"> people</span>.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_ship_2} alt="Glam Area" />
                            <Carousel.Caption>
                            <p>The cabins include the Glam Area: because there's nothing more important than having space to get gorgeous.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_ship_4} alt="Signature Virgin Hand-woven Hammock" />
                            <Carousel.Caption>
                            <p>Every balcony cabin includes a hammock for lazily reading or sipping a drink. Hand-woven in Virgin's signature red by mothers in Thailand, they're as comfy as they are good for the world.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_ship_5} alt="Richard's Rooftop Access" />
                            <Carousel.Caption>
                            <p>Staying in one of the Rock Star Suites gets you access to the private sundeck filled with panoramic ocean views, lavish cocktail hours, and late-night parties under the stars. </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vv_ship_6} alt="Pools and pool-side bars" />
                            <Carousel.Caption>
                            <p>Relax away the day or get to know new friends in any of the gorgeous pools and pool-side bars.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>


                    <p className="legalease">Photos &copy; Virgin Voyages. Activities and amenities vary by ship.</p>
                    
                    <ConsultationRequestTrigger color="red"  
                        text={<>Get a Dose of Vitamin Sea
                         <small>Let's talk about your vacation dreams!</small></>} 
                        onClick={()=>{startConsultationRequest('sample_virginvoyages')}} />
                    </>
                },
                {
                    headline:"An Epic Sea Change is Launching",
                    linktext:"Viking River Cruises",
                    key:"viking",
                    content:<>
                    <Row>
                        <Col xs={{span:12,order:2}} sm={{span:12,order:2}} md={{span:12,order:2}} lg={{span:12,order:2}} xl={{span:10,order:1}}>
                            <p> Viking is destination focused and the world’s leader with more awards that any other river cruise company. Explore Europe, Russia, China and Southeast Asia with Viking to fully experience the history, art, food, wine and culture of each region. Sail right into the heart of the destination along the Rhine, Danube, Seine, Rhone, Elbe or Douro rivers on state-of-the-art, award-winning ships. Enjoy an unforgettable journey with Viking and experience spacious & comfortable accommodations, regional cuisine, included daily shore excursions and exemplary service, all at an incredible value.
                            </p>
                        </Col>
                        <Col xs={{span:12,order:1}} sm={{span:12,order:1}} md={{span:12,order:1}} lg={{span:12,order:1}} xl={{span:2,order:2}}>
                            <img src={cruiselogo_viking} width="200" height="133" className="sample_cruiseline_logo" alt="Viking River Cruises Logo" />
                        </Col>
                    </Row>
                    <Carousel nextIcon={<FontAwesomeIcon icon={faChevronDoubleRight} size="2x" /> } 
                            prevIcon={<FontAwesomeIcon icon={faChevronDoubleLeft} size="2x" /> } 
                            onSelect={(selectedIndex,e)=>{handleCarouselSelect('viking',1,selectedIndex)}} activeIndex={vikingCarousel1Index} interval={null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_ship_1} alt="" />
                            <Carousel.Caption>
                            <p>Some of the world's best sights are far from the ocean, but a river cruise gets you up close to iconic places<span className="hidden-xs hidden-sm hidden-md"> and welcoming people</span> all around the world.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_ship_2} alt="" />
                            <Carousel.Caption>
                            <p>Designed to navigate the great rivers of the world, enjoy convenient daily access to plethora of desintations and experiences. </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_ship_3} alt="" />
                            <Carousel.Caption>
                            <p>These award-winning Viking Longships showcase innovative engineering, streamlined Scandanavian design, and understated elegance.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_ship_4} alt="" />
                            <Carousel.Caption>
                            <p>Understated elegance throughout the longship means you'll feel like a million bucks and arrive in style.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_ship_5} alt="" />
                            <Carousel.Caption>
                            <p>The rooms are spacious and comfortable, with full-size verandas so you can sip your morning coffee as the world slips by.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_ship_6} alt="" />
                            <Carousel.Caption>
                            <p>Viking's Longships feature a wide range of cabin and suite styles to accommodate you and your partner, or a group of longtime friends<span className="hidden-xs hidden-sm hidden-md"> out to see the world</span>.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_ship_7} alt="" />
                            <Carousel.Caption>
                            <p>Elegance is the name of the game with Viking River Cruises, with the most al fresco dining spaces of any European river cruise.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_ship_8} alt="" />
                            <Carousel.Caption>
                            <p>Hospitality is the hallmark of a Viking River Cruise, with one of the most loyal staffs in the industry dedicated to exceeding your expectations.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_ship_10} alt="" />
                            <Carousel.Caption>
                            <p>Viking is the Thinking Person's Cruise, with a wide range of enrichment opportunities from guest lectures, to cooking lessons, and special destination performances.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_places_1} alt="" />
                            <Carousel.Caption>
                            <p>Journey into the heart of Europe, visit Amsterdam by canal, and get a taste of the old world, while traveling in new world elegance.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_places_2} alt="" />
                            <Carousel.Caption>
                            <p>Viking River Cruises offers exclusive access to cultural treasures around the world, including rare collections and off-hours access to places the regular public doesn't get to see.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_places_3} alt="" />
                            <Carousel.Caption>
                            <p>Viking River Cruises are available all around the world, including China, Russia, Egypt, and even the Mississippi.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={vrc_places_4} alt="" />
                            <Carousel.Caption>
                            <p>See the world from a different angle, on gentle and comfortable ships, with people 
                                like you who demand a higher level of service and satisfaction. 
                            </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>


                    <p className="legalease">Photos &copy; Viking River Cruises. Activities and amenities vary by ship.</p>
                    
                    <ConsultationRequestTrigger color="red"  
                        text={<>Enrich Your Worldview
                         <small>Let's talk about your vacation dreams!</small></>} 
                        onClick={()=>{startConsultationRequest('sample_vikingcruises')}} />
                    </>
                },
            ]} />
            

        <PageSectionHeadline id={`CruiseLines_Headline`} color="red">Outward's Cruise Line Partners</PageSectionHeadline>
        <p>There's a cruise experience to suit everyone's desires and preferences - from large city-ships with all the amenities you need to small ship adventure experiences. Outward works with all major cruise lines, and will 
            help you craft the vacation experience you're dreaming of!
        </p>


        <div className="cruiseline-companies">
        <Row className="row-75" lg={2} xl={4}>
            <Col><img src={cruiselogo_rcl} alt="Royal Caribbean" width="200" height="50"/></Col>
            <Col><img src={cruiselogo_carnival} alt="Carnival Cruise Lines" width="200" height="49"/></Col>
            <Col><img src={cruiselogo_disney} alt="Disney Cruise Line" width="200" height="69"/></Col>
            <Col><img src={cruiselogo_celebrity} alt="Celebrity Cruises" width="200" height="71"/></Col>
        </Row>
        <Row className="row-150" lg={2} xl={4}>
            <Col><img src={cruiselogo_viking} alt="Viking River Cruises" width="200" height="133"/></Col>
            <Col><img src={cruiselogo_vv} alt="Virgin Voyages" width="135" height="135"/></Col>
            <Col><img src={cruiselogo_cunard} alt="Cunard" height="135"/></Col>
            <Col><img src={cruiselogo_crystal} alt="Crystal Cruises" width="200" height="133"/></Col>
        </Row>
        <Row className="row-75" lg={2} xl={4}>
            <Col><img src={cruiselogo_msc} alt="MSC Cruises" width="200" height="75"/></Col>
            <Col><img src={cruiselogo_atlas} alt="Atlas Ocean Voyages" width="200" height="66"/></Col>
            <Col><img src={cruiselogo_holland} alt="Holland America Line" width="200" height="52"/></Col>
            <Col><img src={cruiselogo_norwegian} alt="Norwegian Cruise Line" width="200" height="30"/></Col>
        </Row>
        <Row className="row-65" lg={2} xl={4}>
            <Col><img src={cruiselogo_oceania} alt="Oceania Cruises" width="200" height="52"/></Col>
            <Col><img src={cruiselogo_olivia} alt="Olivia" width="182" height="51"/></Col>
            <Col><img src={cruiselogo_paulgauguin} alt="Paul Gauguin" width="200" height="64"/></Col>
            <Col><img src={cruiselogo_princess} alt="Princess" width="200" height="50"/></Col>
        </Row>
        <Row className="row-85">
            <Col><img src={cruiselogo_silversea} alt="Silversea" width="200" height="45"/></Col>
            <Col><img src={cruiselogo_scenic} alt="Scenic Luxury Cruises" width="200" height="75"/></Col>
            <Col><img src={cruiselogo_uncruise} alt="Uncruise Adventures" width="200" height="64"/></Col>
        </Row>
        <Row>
            <Col><img src={cruiselogo_seabourn} alt="Seabourn" width="200" height="85"/></Col>
            <Col><img src={cruiselogo_ritz} alt="Ritz-Carlton Yacht Collection" width="189" height="90"/></Col>
        </Row>
        </div>


        </Tier3Layout>
    </main>
  )
}

 
export default Cruises;

